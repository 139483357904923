import { PERCENTAGE_ROUNDING_PRECISION } from 'components/template-designer/constants';
import { roundToNearestDecimal, roundToNearestNumber } from 'components/template-designer/utils/roundNumbers';

class NumberHelpers {
    /**
     * Normalize a number from one range to another.
     * @param num Initial number.
     * @param inMin Minimum input number
     * @param inMax Maximum input number
     * @param outMin Minimum output number
     * @param outMax Maximum output number
     * @returns Normalized number.
     */
    static normalizeNumber(num: number, inMin: number, inMax: number, outMin: number, outMax: number): number {
        return ((num - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
    }

    /**
     * Get a random number between a range.
     * @param min Minimal number
     * @param max Maximum number
     * @returns Random number
     */
    static getRandomNumber(min: number, max: number): number {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    /**
     * Calculate what percentage one number is of another.
     *
     * @param part - The part value
     * @param whole - The whole value
     * @returns The percentage value
     */
    static calculatePercentage(whole: number, part: number): number {
        if (whole === 0) {
            return 0;
        }

        const percentage = (part / whole) * 100;
        return roundToNearestDecimal(percentage, PERCENTAGE_ROUNDING_PRECISION);
    }
}

export { NumberHelpers };
