import React from 'react';
import Grid from '@mui/material/Grid2';
import { withComponentStore } from 'components/data/ComponentStore';
import OutputCard from 'components/feed-management/OutputCard';
import Translation from 'components/data/Translation';
import EmptyState from 'components/ui-components-cape/EmptyState';
import Illustration from 'components/ui-components-cape/Illustration';
import '../styles/feed-management-outputs.scss';

/**
 * Class Outputs Preview
 * This component shows a list of all the outputs
 */
class Outputs extends React.Component {
    render() {
        const { dataSet } = this.props;

        return (
            <div className="feed-manager-outputs">
                {dataSet && dataSet.outputs && dataSet.outputs.length > 0 && (
                    <Grid container spacing={3}>
                        {dataSet.outputs.map((output) => (
                            <Grid size={4} key={`output-card-${output._id}`}>
                                <OutputCard output={output} dataSet={dataSet} callback={() => this.callback()} />
                            </Grid>
                        ))}
                    </Grid>
                )}
                {dataSet && (!dataSet.outputs || dataSet.outputs.length === 0) && (
                    <EmptyState
                        size="large"
                        className="feed-manager-outputs__empty-state"
                        primaryText={Translation.get('outputs.noOutputsFound', 'feed-management')}
                        illustration={<Illustration size="large" illustration="fallback" color="disabled" />}
                    />
                )}
            </div>
        );
    }
}

export default withComponentStore(Outputs, 'FeedManager', []);
