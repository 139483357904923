import { BrandGuideColor, Color, ColorOptions, Hex, Rgba, SimpleColor } from 'types/color.type';
import { BrandSettingsColors } from 'types/brandSettings.type';
import { RegexHelpers } from './regex.helpers';

class ColorHelpers {
    static DEFAULT_HEX: Hex = '#000000';
    static DEFAULT_RGB: Rgba = { r: 0, g: 0, b: 0, a: 1 };
    static DEFAULT_RGB_ARRAY: [number, number, number, number] = [this.DEFAULT_RGB.r, this.DEFAULT_RGB.g, this.DEFAULT_RGB.b, this.DEFAULT_RGB.a ?? 1];
    static HEX_LENGTH = 7;

    /**
     * Converts a string rgb color to a number array with rgb values.
     * @param CSS rgb string color.
     * @returns Rgb(a) value in an array.
     */
    static getRgbValueFromString = (colorString: string): number[] | null => {
        const rgbaRegex = /^rgba?\((\d*\.?\d+),\s*(\d*\.?\d+),\s*(\d*\.?\d+)(?:,\s*(\d*\.?\d+))?\)$/i;
        const match = rgbaRegex.exec(colorString);
        if (!match) {
            throw new Error('Invalid RGB/RGBA string format');
        }
        const [, r, g, b, a = 1] = match;
        return [Math.round(Number(r)), Math.round(Number(g)), Math.round(Number(b)), Number(a)];
    };

    /**
     * Convert hex color to rgb(a) color.
     * @param hex - Hex color value
     * @returns {array} RGB(A) representation of the hex color.
     */
    static convertHexToRgb = (hex: string): Rgba | undefined => {
        const regex = {
            hex3d: /^#?([0-9a-f])([0-9a-f])([0-9a-f])$/i,
            hex6d: /^#?([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2})$/i,
            hex8d: /^#?([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2})$/i
        };

        if (!hex) return;
        const matches = hex.match(regex.hex3d) || hex.match(regex.hex6d) || hex.match(regex.hex8d);
        if (!matches) return;

        const rgb = matches.slice(1).map((ch, i) => {
            const decimal = parseInt(ch.padStart(2, ch), 16);
            return i === 3 ? Math.round((decimal / 255) * 100) : decimal;
        });

        return {
            r: rgb[0],
            g: rgb[1],
            b: rgb[2],
            a: rgb[3] ? rgb[3] / 100 : 1
        };
    };

    /**
     * Converts an rgb(a) color to hex.
     * @param rgb - Rgb(a) color.
     * @returns An hex representation of the color.
     */
    static convertRgbToHex = (rgb: number[]): string | undefined => {
        if (!Array.isArray(rgb) || ![3, 4].includes(rgb.length)) return;

        const hex = rgb
            .map((ch, i) => {
                const decimal = i === 3 ? Math.round(ch * 255).toString(16) : ch;
                return decimal.toString(16).padStart(2, '0');
            })
            .join('');

        return '#' + hex;
    };

    /**
     * Converts the hex color with an hex alpha value.
     * @param hex - Hex color.
     * @param alpha - Alpha value.
     * @returns Hex color with alpha value.
     */
    static convertHexToHexAlpha = (hex: string, alpha: number): string => {
        // Ensure that alpha is within the valid range of 0 to 1
        alpha = Math.min(1, Math.max(0, alpha));

        // Convert alpha to a rounded hexadecimal value
        const alphaHex = Math.round(alpha * 255)
            .toString(16)
            .toUpperCase();

        // If the alphaHex is a single character, pad it with a leading zero
        const paddedAlphaHex = alphaHex.length === 1 ? `0${alphaHex}` : alphaHex;

        // Append the alpha value to the original hex color
        const hexWithAlpha = hex + paddedAlphaHex;

        return hexWithAlpha;
    };

    /**
     * Convert cmyk color to rgb color.
     * @param cmyk - CMYK color.
     * @param alpha - Alpha value (default: 1)
     * @returns Rgba color from cmyk.
     */
    static convertCmykToRgb = (cmyk: number[], alpha?: number): number[] => {
        const r = 255 * (1 - cmyk[0]) * (1 - cmyk[3]);
        const g = 255 * (1 - cmyk[1]) * (1 - cmyk[3]);
        const b = 255 * (1 - cmyk[2]) * (1 - cmyk[3]);
        return [r, g, b, alpha || 1];
    };

    /**
     * Convert cmyk color to hex color.
     * @param cmyk - Cmyk color.
     * @returns Hex color.
     */
    static convertCmykToHex = (cmyk: number[]): string => {
        const r = Math.round(255 * (1 - cmyk[0]) * (1 - cmyk[3]));
        const g = Math.round(255 * (1 - cmyk[1]) * (1 - cmyk[3]));
        const b = Math.round(255 * (1 - cmyk[2]) * (1 - cmyk[3]));
        return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
    };

    /**
     * Convert rgb color to cmyk.
     * @param rgb - Rgb color.
     * @param alpha - Alpha (default 1).
     * @returns Cmyk color.
     */
    static convertRgbToCmyk = (rgb: number[], alpha?: number): number[] => {
        // Convert RGB values to percentages
        const r = rgb[0] / 255;
        const g = rgb[1] / 255;
        const b = rgb[2] / 255;

        // Calculate maximum percentage value of RGB
        const max = Math.max(r, g, b);

        // Calculate CMY values
        const c = (max - r) / max;
        const m = (max - g) / max;
        const y = (max - b) / max;

        // Calculate K value
        const k = (1 - max) * (1 - (alpha || 1));

        // Return CMYK values
        return [c, m, y, k];
    };

    /**
     * Convert the hex opacity to an opacity value.
     * @param hex - Hex color.
     * @returns Hex color with alpha value.
     */
    static convertHexAlphaToOpacity = (hex: string): number => {
        if (!ColorHelpers.isHexColor(hex)) return 1;

        // Extract the alpha channel if present (last two characters), otherwise default to FF (fully opaque).
        const alphaHex = hex.length === 9 ? hex.slice(7, 9) : 'FF';

        // Convert hex to decimal (0-255) and normalize to 0-100.
        return Math.round((parseInt(alphaHex, 16) / 255) * 100);
    };

    /**
     * Converts a color name to hex.
     * @param color a named color
     * @returns hex color
     */
    static convertColorNameToHex = (color: string): string => {
        const ctx = document.createElement('canvas').getContext('2d');
        if (!ctx) return '#000000';
        ctx.fillStyle = color;
        return ctx.fillStyle.toUpperCase();
    };

    /**
     * Checks if the provided string is a hex color.
     * @param string - Hex color
     * @returns If the provided string is a hex color or not.
     */
    static isHexColor = (hex: string): boolean => /^#[0-9A-Fa-f]{6}$/.test(hex) || /^#[0-9A-Fa-f]{8}$/.test(hex);

    /**
     * Checks if the provided string is a hex color with alpha.
     * @param hex - Hex color.
     * @returns If the provided string is a hex color with alpha or not.
     */
    static hexHasAlpha = (hex: string): boolean => /^#[0-9A-Fa-f]{8}$/.test(hex);

    /**
     * Remove the alpha from the hex string color.
     * @param hex - Hex color.
     * @returns Hex color without alpha.
     */
    static removeAlphaFromHex = (hex: string): string => {
        if (!ColorHelpers.hexHasAlpha(hex)) return hex;
        return hex.slice(0, 7);
    };

    /**
     * Determines if the text should be light or dark based on the background color.
     * @param hex hex color
     * @returns true if text should be light, false if text should be dark
     */
    static shouldTextBeLightOnBackgroundColor = (hex: string): boolean => {
        // Remove the hash if it's there
        hex = hex.replace('#', '');

        // Convert hex to RGB
        const r = parseInt(hex.substring(0, 2), 16);
        const g = parseInt(hex.substring(2, 4), 16);
        const b = parseInt(hex.substring(4, 6), 16);

        // Calculate the luminance https://en.wikipedia.org/wiki/YIQ
        const yiq = (r * 299 + g * 587 + b * 114) / 1000;

        // Return false for light backgrounds and true for dark backgrounds
        return yiq >= 128 ? false : true;
    };

    /**
     * Checks if the provided color is a simple color.
     * @param color - Color object.
     * @returns If the provided color is a simple color or not.
     */
    static isSimpleColor = (color: Color | SimpleColor | BrandGuideColor): color is SimpleColor => {
        return 'hex' in color && 'rgb' in color;
    };

    /**
     * Checks if the provided color is a color object.
     * @param color - Color object.
     * @returns If the provided color is a color object or not.
     */
    static isColor = (color: Color | SimpleColor | BrandGuideColor): color is Color => {
        return !('hex' in color && 'rgb' in color);
    };

    /***
     * Checks if the provided color is a brand guide color.
     * @param color - Color object.
     * @returns If the provided color is a brand guide color or not.
     */
    static isBrandGuideColor = (color: Color | SimpleColor | BrandGuideColor): color is BrandGuideColor => {
        return 'color' in color;
    };

    /**
     * Converts a simple color to a color object.
     * @param color - Simple color.
     * @returns Color object.
     */
    static convertSimpleColorToColor = (color: SimpleColor): Color => {
        return {
            type: ColorOptions.Solid,
            rotation: 0,
            points: [
                {
                    color: color,
                    location: 0
                }
            ]
        };
    };

    /**
     * Converts a color to a simple color.
     * @param color - Color object.
     * @returns Simple color.
     */
    static convertColorToSimpleColor = (color: Color): SimpleColor => {
        return color.points[0].color;
    };

    /**
     * Converts a brand guide color to a color object.
     * @param color - Brand guide color.
     * @returns Color object.
     */
    static convertBrandGuideColorToColor = (color: BrandSettingsColors | BrandGuideColor): Color => {
        if (typeof color.color === 'string') {
            return this.getColorObjectFromString(color.color);
        }

        return color.color;
    };

    /**
     * Get the color type.
     * @param color - Color object.
     * @returns Color type.
     */
    static getColorType = (color: Color | SimpleColor): ColorOptions => {
        if ('type' in color) return color.type;
        return ColorOptions.Solid;
    };

    /**
     * Get the color rotation.
     * @param color - Color object.
     * @returns Color rotation.
     */
    static getColorRotation = (color: Color | SimpleColor): number => {
        if ('rotation' in color) return color.rotation;
        return 0;
    };

    /**
     * Get the color points.
     * @param color - Color object.
     * @returns Color points.
     */
    static getColorPoints = (color: Color | SimpleColor): Color['points'] => {
        if ('points' in color) return color.points;
        return [];
    };

    /**
     * Converts a css color to a color object.
     * @param color - Color string.
     * @returns Color object.
     */
    static getColorObjectFromString = (color: string): Color => {
        if (color === 'transparent') {
            return {
                type: ColorOptions.Transparent,
                rotation: 0,
                points: [
                    {
                        location: 0,
                        color: {
                            hex: '#ffffff',
                            rgb: {
                                r: 0,
                                g: 0,
                                b: 0,
                                a: 1
                            }
                        }
                    },
                    {
                        location: 1,
                        color: {
                            hex: this.DEFAULT_HEX,
                            rgb: this.DEFAULT_RGB
                        }
                    }
                ]
            };
        }

        // If the color starts with an '#', then it is an HEX color.
        if (color.startsWith('#')) {
            const rgb = this.convertHexToRgb(color) ?? this.DEFAULT_RGB;

            return {
                type: ColorOptions.Solid,
                rotation: 0,
                points: [
                    {
                        location: 0,
                        color: {
                            hex: color,
                            rgb: rgb
                        }
                    },
                    {
                        location: 1,
                        color: {
                            hex: this.DEFAULT_HEX,
                            rgb: this.DEFAULT_RGB
                        }
                    }
                ]
            };
        }

        // If the color starts with an 'rgb', then it is an RGB or RGBA color.
        if (color.startsWith('rgb')) {
            const rgbArray = this.getRgbValueFromString(color) ?? this.DEFAULT_RGB_ARRAY;
            const hex = this.convertRgbToHex(rgbArray) || this.DEFAULT_HEX;

            const rgb = {
                r: rgbArray[0],
                g: rgbArray[1],
                b: rgbArray[2],
                a: rgbArray[3] !== undefined ? rgbArray[3] : undefined
            };

            return {
                type: ColorOptions.Solid,
                rotation: 0,
                points: [
                    {
                        location: 0,
                        color: {
                            hex,
                            rgb
                        }
                    },
                    {
                        location: 1,
                        color: {
                            hex: this.DEFAULT_HEX,
                            rgb: this.DEFAULT_RGB
                        }
                    }
                ]
            };
        }

        const match = RegexHelpers.extractMatches('cssGradient', color);

        const gradientType = (() => {
            if (match && match[0]) {
                return match[0];
            }

            return ColorOptions.Linear;
        })();

        const colors: { color: string; position: string }[] = [];
        let colorMatch;

        while ((colorMatch = RegexHelpers.exec('cssColor', color)) !== null) {
            colors.push({ color: colorMatch[1], position: colorMatch[2] });
        }

        const rotationMatch = RegexHelpers.extractMatches('rotationCssColor', color);

        let rotation = 0;

        if (rotationMatch) {
            const value = parseFloat(rotationMatch[1]);
            const unit = rotationMatch[2];
            const direction = rotationMatch[0] || rotationMatch[3];

            if (!isNaN(value)) {
                if (unit === 'deg') {
                    rotation = value;
                } else if (unit === 'rad') {
                    rotation = (value * 180) / Math.PI;
                } else if (unit === 'turn') {
                    rotation = value * 360;
                }
            } else if (direction) {
                const angleMap = {
                    'to top': 0,
                    'to top right': 45,
                    'to right': 90,
                    'to bottom right': 135,
                    'to bottom': 180,
                    'to bottom left': 225,
                    'to left': 270,
                    'to top left': 315
                };

                rotation = angleMap[direction];
            }
        }

        // Create a start object so this can be modified in this function.
        return {
            type: gradientType as ColorOptions,
            rotation: Number(rotation),
            points: colors.map(({ color, position }) => {
                const hex = (() => {
                    if (color.startsWith('#')) {
                        return color;
                    }

                    return this.convertRgbToHex(this.getRgbValueFromString(color) ?? this.DEFAULT_RGB_ARRAY) ?? this.DEFAULT_HEX;
                })();

                const rgb = (() => {
                    if (color.startsWith('#')) {
                        const result = this.convertHexToRgb(color);

                        if (result) {
                            return result;
                        }
                    }

                    const rgbArray = this.getRgbValueFromString(color) ?? this.DEFAULT_RGB_ARRAY;

                    if (rgbArray && rgbArray.length >= 3) {
                        return {
                            r: rgbArray[0],
                            g: rgbArray[1],
                            b: rgbArray[2],
                            a: rgbArray[3] !== undefined ? rgbArray[3] : 1
                        };
                    }

                    return this.DEFAULT_RGB;
                })();

                return {
                    location: Number(RegexHelpers.extractMatches('containsNumbers', position)) / 100,
                    color: {
                        hex,
                        rgb
                    }
                };
            })
        };
    };

    /**
     * Converts a color object to a css color string.
     * @param color - Color object.
     * @returns Css color string.
     */
    static getCssColor = (color: Color | SimpleColor): string => {
        if (!color) {
            return this.DEFAULT_HEX;
        }

        if (this.isSimpleColor(color)) {
            return color.hex;
        }

        if (color.type === ColorOptions.Solid) {
            return color.points[0]?.color?.hex;
        }

        if (color.type === ColorOptions.Linear) {
            const points = color.points.map((point) => {
                const { r, g, b, a } = point.color.rgb;
                return `rgba(${r},${g},${b},${a}) ${point.location * 100}%`;
            });

            return `linear-gradient(${color.rotation || 0}deg, ${points.toString()})`;
        }

        if (color.type === ColorOptions.Radial) {
            const points = color.points.map((point) => {
                const { r, g, b, a } = point.color.rgb;
                return `rgba(${r},${g},${b},${a}) ${point.location * 100}%`;
            });
            return `radial-gradient(${points.toString()})`;
        }

        return ColorOptions.Transparent;
    };
}

export { ColorHelpers };
