import React from 'react';
import { connect } from 'react-redux';
import MultiEmailBuilderComponent from 'components/channels/email/MultiEmailBuilder';

const MultiEmailPreview = ({ editor, abTesting, abTestGroupsShown, onSetActiveEmailId }) => {
    return (
        <div>
            <MultiEmailBuilderComponent
                data={editor.previewSetup}
                language={editor.language}
                additionalProps={{ abTestGroupsShown, abTesting, onSetActiveEmailId, review: true }}
            />
        </div>
    );
};

function mapStateToProps(state) {
    return { editor: state.editor };
}

export default connect(mapStateToProps)(MultiEmailPreview);
