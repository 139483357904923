import * as Sentry from '@sentry/react';
import Translation from 'components/data/Translation';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';

/**
 * Model to copy and paste data to the clipboard.
 */
export class Clipboard {
    private static lastSnackbarTime = 0;
    private static readonly SNACKBAR_DEBOUNCE_MS = 500;

    /**
     * Copy data to clipboard.
     * Can be any data type, but will be converted to JSON string.
     * @param data - Data to copy to clipboard.
     */
    static write = async <Data>(data: Data): Promise<void> => {
        try {
            const text = JSON.stringify(data);
            await navigator.clipboard.writeText(text);
        } catch (error: unknown) {
            Sentry.captureException(error);
        }
    };

    /**
     * Read data from clipboard.
     * @returns {Promise} Promise that resolves to data from clipboard.
     */
    static read = async <Data>(): Promise<Data | undefined | void> => {
        try {
            const clipboardAvailable = 'clipboard' in navigator && 'read' in navigator.clipboard;

            if (!clipboardAvailable) {
                return new Promise((resolve) => {
                    const handlePaste = (event: ClipboardEvent) => {
                        const clipboardData = event.clipboardData;

                        // Handle text.
                        const text = clipboardData?.getData('text/plain');
                        if (text) {
                            try {
                                const data = JSON.parse(text);
                                resolve(data);
                            } catch {
                                resolve(undefined);
                            }
                        }

                        // Handle image.
                        if (clipboardData?.items) {
                            for (let i = 0; i < clipboardData.items.length; i++) {
                                const item = clipboardData.items[i];
                                if (item.type.startsWith('image/')) {
                                    try {
                                        const imageBlob = item.getAsFile();
                                        if (imageBlob) {
                                            const imageFile = new File([imageBlob], 'image.png', { type: imageBlob.type });
                                            resolve(imageFile as Data);
                                        }
                                    } catch {
                                        resolve(undefined);
                                    }
                                }
                            }
                        }

                        resolve(undefined);
                    };

                    document.addEventListener('paste', handlePaste, { once: true });
                });
            }

            const clipboardItems = await navigator.clipboard.read();
            for (const item of clipboardItems) {
                // Handle text.
                if (item.types.includes('text/plain')) {
                    try {
                        const textBlob = await item.getType('text/plain');
                        const text = await textBlob.text();
                        return JSON.parse(text);
                    } catch {
                        return;
                    }
                }

                // Handle image.
                if (item.types.includes('image/png')) {
                    try {
                        const imageBlob = await item.getType('image/png');
                        const imageFile = new File([imageBlob], 'image.png', { type: 'image/png' });
                        return imageFile as Data;
                    } catch {
                        return;
                    }
                }
            }

            return;
        } catch (error) {
            // Show snackbar on permission denied.
            if (error instanceof DOMException && error.name === 'NotAllowedError') {
                const now = Date.now();
                if (now - this.lastSnackbarTime < this.SNACKBAR_DEBOUNCE_MS) return; // Debounce snackbar.
                this.lastSnackbarTime = now;
                return SnackbarUtils.error(Translation.get('errors.clipboardPermissionDenied', 'common'));
            }

            Sentry.captureException(error);
        }
    };

    /**
     * Clear clipboard.
     */
    static clear = async (): Promise<void> => {
        try {
            await navigator.clipboard.writeText('');
        } catch (error: unknown) {
            Sentry.captureException(error);
        }
    };
}
