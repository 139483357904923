import Translation from 'components/data/Translation';

class StringHelpers {
    /**
     * Convert camel case text to readable text.
     * @param text - Text to convert to readable format.
     * @returns Readable text.
     */
    static camelCaseToReadable(text: string): string {
        return (
            text.charAt(0).toUpperCase() +
            text
                .slice(1)
                .replace(/([A-Z])/g, ' $1')
                .toLowerCase()
        );
    }

    /**
     * Removes non-breaking spaces from a string.
     * @param text - Text to remove non-breaking spaces from.
     * @returns Text without non-breaking spaces.
     */
    static removeNonBreakingSpaces(text: string): string {
        return text.replace(/&nbsp;|\u00A0/g, ' ');
    }

    /**
     * Join a list of strings with commas and 'and' for the last item
     * @param list - List of strings
     * @returns - Joined string
     */
    static commaAndJoin = (list: string[]): string => {
        if (list.length > 1) {
            return list.slice(0, -1).join(', ') + ` ${Translation.get('labels.and', 'common')} ` + list.slice(-1);
        }
        return list[0] || '';
    };
}

export { StringHelpers };
